import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import { theme } from './aikya';
import { HeadingXXL } from './typography';
import Header from './header';
import ContactForm from './contactform';
import IntroBlock from './introblock';
import ContactBlock from './contactblock';

class Page extends React.Component {

    constructor(props) {

		super(props);
    }

    render() {

        return (
			<ThemeProvider theme={theme}>
				<Header />
				<ContactBlock />
			</ThemeProvider>
		)
	}
}

// ========================================

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Page />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
