import React, { useState } from "react";
import styled from 'styled-components';

import {
	BodyXS,
	HeadingL,
	Heading2XSUppercase
} from "./typography";
import Button from "./button";

const ContentContainer = styled.div`
	margin: 1.75rem 1.5rem;
	max-width: 640px;
`;

const Kicker = styled(Heading2XSUppercase)`
	color: ${(props) => props.theme.colors.yellow6};
	margin: 0.5rem 0;
`;

const ButtonContainer = styled.div`
	margin-top: 2rem;
`;

class ContactBlock extends React.Component {

	render() {
		return (
			<ContentContainer>
				<HeadingL>Join our Discord!</HeadingL>
				<BodyXS>Ask&nbsp;questions, keep&nbsp;up with development news, and tell&nbsp;us what&nbsp;you&nbsp;think!</BodyXS>
				<ButtonContainer>
					<Button fullWidth label="Accept invitation" link="https://discord.gg/rRCda9KUWR"/>
				</ButtonContainer>
			</ContentContainer>
		);
	}
}

export default ContactBlock;