import styled, { keyframes } from 'styled-components';
import { BodyXS } from './typography';

/* AIKYA 241102 */

export const theme = {
	typography: {
		bodyXS: {
			fontSize: "17px",
			fontWeight: "normal",
			lineHeight: "23px",
			letterSpacing: "0"
		},
		headingXXL: {
			fontSize: "81px",
			fontWeight: "bold",
			lineHeight: "85px",
			letterSpacing: "-2.43px"
		},
		headingL: {
			fontSize: "37px",
			fontWeight: "bold",
			lineHeight: "43px",
			letterSpacing: "-0.74px"
		},
		heading2XS: {
			fontSize: "14px",
			fontWeight: "bold",
			lineHeight: "18px",
			letterSpacing: "0.14px"
		}
	},
	colors : {
		neutral1: "#0f0f0f",
		neutral2: "#212121",
		neutral3: "#383838",
		neutral4: "#525252",
		neutral5: "#767676",
		neutral6: "#adadad",
		neutral7: "#c7c7c7",
		neutral8: "#dedede",
		neutral9: "#f0f0f0",
		neutral10: "#ffffff",
	
		red1: "#250a04",
		red2: "#4e1409",
		red3: "#7b1d0a",
		red4: "#c13a1f",
		red5: "#db4f33",
		red6: "#fc8f79",
		red7: "#ffb2a3",
		red8: "#ffd0c7",
		red9: "#ffeae5",
	
		orange1: "#290c00",
		orange2: "#471900",
		orange3: "#752d00",
		orange4: "#c24e00",
		orange5: "#db6a00",
		orange6: "#f69e4c",
		orange7: "#ffbd80",
		orange8: "#ffd7ad",
		orange9: "#ffebd6",
	
		yellow1: "#241a00",
		yellow2: "#3d2d00",
		yellow3: "#5c4700",
		yellow4: "#8f7100",
		yellow5: "#b89700",
		yellow6: "#e5c100",
		yellow7: "#e7d55a",
		yellow8: "#eee087",
		yellow9: "#faf4d1",
		
		green1: "#091f00",
		green2: "#143800",
		green3: "#255a02",
		green4: "#428217",
		green5: "#60a136",
		green6: "#92ca6d",
		green7: "#b7d9a0",
		green8: "#d2eac2",
		green9: "#e8f5e0",
	
		teal1: "#001f12",
		teal2: "#023621",
		teal3: "#0a5737",
		teal4: "#1a7f56",
		teal5: "#33a478",
		teal6: "#7dc9aa",
		teal7: "#a5d9c4",
		teal8: "#c3e9da",
		teal9: "#e4f7ef",
	
		blue1: "#081930",
		blue2: "#0f2747",
		blue3: "#1d4071",
		blue4: "#2f61a7",
		blue5: "#4a85d9",
		blue6: "#91baf3",
		blue7: "#b1d0fb",
		blue8: "#d3e5fd",
		blue9: "#e6f0fe",
	
		periwinkle1: "#130b28",
		periwinkle2: "#25184e",
		periwinkle3: "#3e2a7a",
		periwinkle4: "#603ec6",
		periwinkle5: "#8563e8",
		periwinkle6: "#b29afe",
		periwinkle7: "#ccb8ff",
		periwinkle8: "#e3d6ff",
		periwinkle9: "#f2ebff",
	
		purple1: "#1a0925",
		purple2: "#311245",
		purple3: "#50226d",
		purple4: "#723899",
		purple5: "#a457d6",
		purple6: "#cb93f1",
		purple7: "#deb0fd",
		purple8: "#efd1ff",
		purple9: "#f8e5ff",
	
		magenta1: "#250919",
		magenta2: "#3d102a",
		magenta3: "#611e45",
		magenta4: "#962c6a",
		magenta5: "#cd5199",
		magenta6: "#f193ca",
		magenta7: "#fdb0dd",
		magenta8: "#ffd1ec",
		magenta9: "#ffe5f4"
	},
	breakpoints : {
		first: '35rem', 	// 560px
		second: '50rem',	// 800px
		third: '65rem',		// 1040px
		fourth: '80rem', 	// 1280px
		fifth: '95rem',		// 1520px
		sixth: '110rem',	// 1760px
		seventh: '150rem'	// 2400px
	}
}