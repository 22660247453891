import React, { useState } from "react";
import styled from 'styled-components';

import { Heading2XSUppercase } from "./typography";

import headerLogoWordmarkImage from './images/logo-wordmark.svg';
import headerLogoSymbolImage from './images/logo-symbol.svg';

const StyledHeader = styled.div`
	margin: 1.125rem 1.5rem;
	max-width: 640px;
`;

const HeaderLogoWordmark = styled.div`
	text-align: center;

	img {
		width: 100%;
	}
`;

const HeaderLogoSymbol = styled.div`
	margin-bottom: 8%;
	margin-top: 10%;
	text-align: center;

	img {
		width: 60%;
	}
`;

const HeaderTagline = styled.div`
	border-bottom: 2px solid ${(props) => props.theme.colors.yellow6};
	display: flex;
	justify-content: space-between;
`;

const HeaderTaglineElement = styled(Heading2XSUppercase)`
	color: ${(props) => props.theme.colors.yellow6};
	margin: 0.5rem 0;
`;

class Header extends React.Component {

	render() {
		return (
			<StyledHeader>
				<HeaderLogoWordmark>
					<img src={headerLogoWordmarkImage} alt="Wordmark Logo" />
				</HeaderLogoWordmark>
				<HeaderLogoSymbol>
					<img src={headerLogoSymbolImage} alt="Symbol Logo" />
				</HeaderLogoSymbol>
				<HeaderTagline>
					<HeaderTaglineElement>Tressym Drone Labs</HeaderTaglineElement>
					<HeaderTaglineElement>tressym.win</HeaderTaglineElement>
				</HeaderTagline>
			</StyledHeader>
		);
	}
}

export default Header;