import React, { useState } from "react";
import styled from 'styled-components';

const StyledButton = styled.a`
	background-color: ${(props) => props.theme.colors.purple4};
	border-radius: 0.25rem;
	color: ${(props) => props.theme.colors.neutral10};
	display: ${props => props.fullWidth ? "block" : "flex"};
	font-size: ${(props) => props.theme.typography.heading2XS.fontSize};
	font-weight: ${(props) => props.theme.typography.heading2XS.fontWeight};
	line-height: ${(props) => props.theme.typography.heading2XS.lineHeight};
	padding: 1rem 1.5rem;
	text-decoration: none;
	text-transform: uppercase;
	text-align: center;

	::hover {
		background-color: ${(props) => props.theme.colors.purple3};
	}
`;

class ButtonLink extends React.Component {

	render() {
		return (
			<StyledButton fullWidth={this.props.fullWidth} href={this.props.link}>
				{this.props.label}
			</StyledButton>
		);
	}
}

export default ButtonLink;