import styled from 'styled-components';

export const HeadingXXL = styled.h1`
	font-size: ${(props) => props.theme.typography.headingXXL.fontSize};
	font-weight: ${(props) => props.theme.typography.headingXXL.fontWeight};
	line-height: ${(props) => props.theme.typography.headingXXL.lineHeight};
	letter-spacing: ${(props) => props.theme.typography.headingXXL.letterSpacing};
`;

export const HeadingL = styled.h4`
	font-size: ${(props) => props.theme.typography.headingL.fontSize};
	font-weight: ${(props) => props.theme.typography.headingL.fontWeight};
	line-height: ${(props) => props.theme.typography.headingL.lineHeight};
	letter-spacing: ${(props) => props.theme.typography.headingL.letterSpacing};
	margin: 1.25rem 0;
`;

export const Heading2XS = styled.h6`
	font-size: ${(props) => props.theme.typography.heading2XS.fontSize};
	font-weight: ${(props) => props.theme.typography.heading2XS.fontWeight};
	line-height: ${(props) => props.theme.typography.heading2XS.lineHeight};
	letter-spacing: ${(props) => props.theme.typography.heading2XS.letterSpacing};
`;

export const Heading2XSUppercase = styled.h6`
	font-size: ${(props) => props.theme.typography.heading2XS.fontSize};
	font-weight: ${(props) => props.theme.typography.heading2XS.fontWeight};
	line-height: ${(props) => props.theme.typography.heading2XS.lineHeight};
	letter-spacing: ${(props) => props.theme.typography.heading2XS.letterSpacing};
	margin: 1.25rem 0;
	text-transform: uppercase;
`;

export const BodyXS = styled.p`
	font-size: ${(props) => props.theme.typography.bodyXS.fontSize};
	font-weight: ${(props) => props.theme.typography.bodyXS.fontWeight};
	line-height: ${(props) => props.theme.typography.bodyXS.lineHeight};
	letter-spacing: ${(props) => props.theme.typography.bodyXS.letterSpacing};
	margin: 1.25rem 0;
`;